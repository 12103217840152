<template>
    <div class="shop_main" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="1" />

            <div class="shop_refund_main">
                <div class="refund_info">
                    <div class="desc pb32" v-if="type === '1'">
                        <h1>说明：</h1>
                        <p>·请选择退款状态，如未收到货或收到货无需退回商品则选择仅退款，如选择退回商品则选择退货退款</p>
                    </div>

                    <div class="desc" v-else>
                        <h1>说明：</h1>
                        <p>·您对当前订单商品支付后想取消购买操作，且与商家协商达成一致退款，请填写"退款单"信息并提交</p>
                        <p>·退款/退货后，商家审核完成，交易钱款将原路径返回付款账户</p>
                    </div>

                    <div class="goodsList" v-if="type === '1'">
                        <div class="item">
                            <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="image" fit="contain"></el-image>

                            <div class="info">
                                <span class="name">东北野山参东北野山参东北野山参东北野山参</span>

                                <span class="text">¥ 78.00<span>×2</span></span>
                            </div>
                        </div>
                    </div>

                    <div class="typeChange" v-if="type === '1'">
                        <div class="item on">
                            <span class="name">仅退款</span>
                            <span class="text">未收到货品（包含未签收）<br />或卖家协商同意</span>
                        </div>

                        <div class="item">
                            <span class="name">退货退款</span>
                            <span class="text">已收到货<br />需要退换已收到的货品</span>
                        </div>
                    </div>

                    <div class="step" v-if="type === '2'">
                        <div class="item on">
                            <div class="num">1</div>
                            <span class="text">买家申请退款</span>
                        </div>
                        <div class="item">
                            <div class="num">2</div>
                            <span class="text">商家处理退款申请</span>
                        </div>
                        <div class="item">
                            <div class="num">3</div>
                            <span class="text">退款完成</span>
                        </div>

                        <div class="line left"></div>
                        <div class="line right"></div>
                    </div>

                    <div class="step else" v-if="type === '3'">
                        <div class="item on">
                            <div class="num">1</div>
                            <span class="text">买家申请退货</span>
                        </div>
                        <div class="item">
                            <div class="num">2</div>
                            <span class="text">商家处理退货申请</span>
                        </div>
                        <div class="item">
                            <div class="num">3</div>
                            <span class="text">买家退货给商家</span>
                        </div>
                        <div class="item">
                            <div class="num">3</div>
                            <span class="text">商家收货，退款完成</span>
                        </div>

                        <div class="line left"></div>
                        <div class="line center"></div>
                        <div class="line right"></div>
                    </div>

                    <el-form ref="form" :model="form" size="small" label-position="left" :rules="rules" label-width="84px">
                        <div class="form_refund">
                            <el-form-item label="支付单：" prop="logisticsType">
                                <el-select v-model="index" placeholder="请选择支付单">
                                    <el-option label="1" value="1"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="退款原因：" prop="logisticsType">
                                <el-select v-model="index" placeholder="请选择退款原因">
                                    <el-option label="1" value="1"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="退款金额：" prop="logisticsType">
                                <el-input v-model="index" placeholder="金额" /><span class="price">元</span><span class="price">（最多<span>¥195.90</span>）</span>
                                <div class="descText">退款金额不能超过可退金额</div>
                            </el-form-item>

                            <el-form-item label="退款说明：" prop="logisticsId">
                                <el-input v-model="form.logisticsCode" type="textarea" :row="4" autocomplete="off" placeholder="请输入退款说明"></el-input>
                            </el-form-item>

                            <el-form-item label="上传凭证：" prop="logisticsId">
                                <el-upload list-type="picture-card" class="avatar">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </div>

                        <div class="submitBtn pt6" v-if="type === '2' || type === '3'">
                            <el-form-item>
                                <el-button type="primary" :loading="isSubmitLoading">确认提交</el-button>
                                <el-button>返 回</el-button>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <div class="refund_order">
                    <div class="info">
                        <div class="title">
                            <h1>订单信息</h1>
                        </div>

                        <div class="goods">
                            <div class="item">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="image" fit="contain"></el-image>

                                <div class="desc">
                                    <div class="name">东北野山参东北野山参东…</div>

                                    <div class="bot">
                                        <div class="text">¥ 78.00</div>
                                        <div class="text">×2</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="title_sub">
                            <h1>订单金额：<span> ¥ 3000.00</span></h1>
                        </div>

                        <div class="order">
                            <div class="item">
                                <div class="text">订单编号：<span class="no">E129120200808110210</span><div class="more">更多></div></div>
                                <div class="text">物流编号：<span class="text">Y1129120200808110210</span></div>
                            </div>

                            <div class="item">
                                <div class="text">商家：<span class="text">东北制药</span></div>
                                <div class="text">客服电话：<span class="no">024-10208080</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  const userMenu = () => import('../../../components/layout/shop/userMenu')

export default {
  name: "shopOrderRefund",
  data () {
    return {
      type: '2', // 1-选择，2-退款，3-退货
      desc: '',
      form: {},
      rules: {},
      isSubmitLoading: false
    }
  },
  created() {
    this.$store.commit('changeScrollTop');
    if (this.type === '1' || this.type === '2') {
      this.desc = '退款'
    }
    if (this.type === '3' || this.type === '4' || this.type === '5') {
      this.desc = '退货'
    }
  },
  methods: {},
  components: {
    userMenu
  }
}
</script>
